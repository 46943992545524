import React from "react";
import adminInsightResult from "graphql/queries/adminInsightResult";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

export const OpenBill = () => {
    const { BillID } = useParams<any>()
    const getInsightResult = useQuery(adminInsightResult, {
        variables: {
            UserID: "GetMeThisBillPlz",
            InsightID: "Bill:"+BillID
        }
    })
    React.useEffect(() => {
        if (getInsightResult.data) openPDF(getInsightResult.data.adminInsightResult)
    }, [getInsightResult.data])

    const openPDF = (pdf) => {
        //Create a Blob from the PDF Stream
        const file = base64toBlob(pdf);
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL,"_self");
    }

    function base64toBlob(base64Data: string) {
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);
      
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
          const begin = sliceIndex * sliceSize;
          const end = Math.min(begin + sliceSize, bytesLength);
      
          const bytes = new Array(end - begin);
          for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
          }
          byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: "application/pdf" });
    }
      
    return (
        <></>
    )
}