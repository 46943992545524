import { useMutation, useQuery } from '@apollo/client'

import * as React from 'react'
import MaterialTable, { Icons } from 'material-table'
import { tableIcons } from 'components/common/MaterialIcons'
import pushes from 'graphql/queries/pushes'

const Pushes = () => {
    const { loading, error, data } = useQuery(pushes, { fetchPolicy: 'no-cache' })
    if (loading) return <div>Loading</div>
    if (error) return <div>failure {console.error(error)} </div>
    if (!data.getPushes) data.getPushes = []

    let thisdata = data.getPushes
        .map((a) => {
            return {
                UserID: a.UserID,
                ID: a.ID,
                Type: a.Type,
                Status: a.Status,
                EmailAddress: a.EmailAddress,
                DateCreated: a.DateCreated,
                DateSent: a.DateSent,
                Title: a.Title,
                CampaignID: a.CampaignID,
            }
        })

    return (
        <div>
            <div>
                <MaterialTable
                    icons={tableIcons as Icons}
                    title={''}
                    columns={[
                        { title: 'Type', field: 'Type' },
                        { title: 'UserID', field: 'UserID' },
                        { title: 'ID', field: 'ID' },
                        { title: 'Status', field: 'Status' },
                        { title: 'EmailAddress', field: 'EmailAddress' },
                        { title: 'DateCreated', field: 'DateCreated', defaultSort: 'desc' },
                        { title: 'DateSent', field: 'DateSent' },
                        { title: 'Title', field: 'Title' },
                        { title: 'CampaignID', field: 'CampaignID' },
                    ]}
                    data={thisdata}
                    options={{
                        showTitle: false,
                        sorting: true,
                        filtering: true,
                        search: true,
                        pageSize: 50,
                    }}
                />
            </div>
        </div>
    )
}

export default Pushes
