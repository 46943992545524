import { ButtonBase } from "@material-ui/core";
import React from "react";
import * as styles from './styles'
import adminInsightResult from "graphql/queries/adminInsightResult";
import { useLazyQuery } from "@apollo/client";
import { Insight } from "graphql/types";

type Props = {
    insight: Insight
    uniqueBills: Map<string, number>
}
export const BillButton = ({insight, uniqueBills}: Props) => {
    const getInsightResult = useLazyQuery(adminInsightResult)
    React.useEffect(() => {
        if (getInsightResult[1].data) openPDF(getInsightResult[1].data.adminInsightResult)
    }, [getInsightResult[1].data])
    const openPDF = (pdf) => {
        //Create a Blob from the PDF Stream
        const file = base64toBlob(pdf);
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }

    const getBill = async (insight: Insight) => {
            console.log(insight)
            await getInsightResult[0]({
                variables: {
                    UserID: insight.UserID,
                    InsightID: "Bill:"+insight.BillId
                }
            })
        };

    function base64toBlob(base64Data: string) {
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);
      
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
          const begin = sliceIndex * sliceSize;
          const end = Math.min(begin + sliceSize, bytesLength);
      
          const bytes = new Array(end - begin);
          for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
          }
          byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: "application/pdf" });
    }
      
    return (
        <ButtonBase className={styles.header}  onClick={() => getBill(insight)}>
            <div>Bill: #{uniqueBills.get(insight.BillHash)}</div>
        </ButtonBase>
    )
}