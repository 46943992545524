import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AcuDashboard from 'components/dashboard/acu'
import AcuInsights from 'components/insights/acu'
import InspectResult from 'components/logs/inspectResult'

const Routes = () => (
    <Switch>
        <Route path="/" exact component={AcuDashboard} />
        <Route path="/insights" component={AcuInsights} />
        <Route path="/inspectResult" exact component={InspectResult} />
    </Switch>
)

export default Routes
