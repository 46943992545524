import * as React from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import users from 'graphql/queries/users'
import { Alert } from '@material-ui/lab'
import { ChakraTable } from 'components/tableWithSearch'
import { MenuItem, Select, Snackbar, capitalize } from '@material-ui/core'
import {
    differenceInMonths,
    differenceInWeeks,
    format,
    formatDistanceToNow,
    isToday,
    isYesterday,
} from 'date-fns'
import { useToast } from '@chakra-ui/react'
import accountsUserTable from 'graphql/queries/accountsUserTable'
import { Account, CarbonResult, Insight, Partner, User } from 'graphql/types'
import { getCustomLogo } from 'utils/logo'
import { prettyInstitutionName } from 'utils/institutions'
import insightsUserTable from 'graphql/queries/insightsUserTable'
import carbonResultsUserTable from 'graphql/queries/carbonResultsUserTable'
import adminUsers from 'graphql/queries/adminUsers'
import partners from 'graphql/queries/partners'

function getRelativeDateLabel(date: Date): string {
    if (isToday(date)) {
        return 'Today'
    } else if (isYesterday(date)) {
        return 'Yesterday'
    } else {
        const daysAgo = formatDistanceToNow(date, { addSuffix: true })
        return daysAgo
    }
}

const noFetch = 'N/A'

const Users = () => {

    const [partnerID, setPartnerID] = React.useState('racq')
    const { loading: partnersLoading, error, data } = useQuery(partners)
    const { loading: userLoading, error: userError, data: userData } = useQuery(adminUsers, {
        variables: { PartnerID: partnerID },
    })

    const [showTest, setShowTest] = React.useState(false)
    const [alertVisible, setAlertVisible] = React.useState(false)
    const toast = useToast()
    if (userLoading) return <div>Loading</div>
    if (userError) return <div>{userError}</div>
    const items: User[] = userData.adminUsers ?? []
    const sortedData = items.slice()
        .filter((i) => showTest || !i.UserAttribute.filter(a => a.key === 'TestUser').length)
        .sort((a, b) => Number(b.lastLogin) - Number(a.lastLogin))

    const p = data?.partners

    return (
        <div>
            <button onClick={() => setShowTest(!showTest)}>
                <div style={{position:'absolute', top: '70px', right: '400px', padding: '5px 10px', zIndex: 1000, background: "lightblue", boxShadow: "2px 2px 2px grey"}}>
                    {showTest ? "Hide Test Users" : "Show Test Users"}
                </div>
            </button>
            {!partnersLoading && p &&  (
                <div style={{position:'absolute', top: '50px', right: '570px', padding: '5px 10px', zIndex: 1000 }}>
                    <Select value={partnerID} onChange={(e) => setPartnerID(e.target.value as string)} style={{fontSize: "22px", padding: "5px 2px"}} >
                        {p.map((p: Partner) => (
                            <MenuItem style={{fontSize: "22px"}} key={p.ID} value={p.ID} >{p.Name}</MenuItem>
                        ))}
                    </Select>
                </div>
            )}
            
            <ChakraTable
                data={sortedData}
                fieldsToSearch={['email', 'user_id', 'lastLogin']}
                columns={[
                    {
                        key: 'email',
                        type: 'avatar',
                        header: 'Email',
                        calculateProps: (i) => i.user_id,
                    },
                    {
                        header: 'Status',
                        key: 'lastLogin',
                        type: 'status',
                        tooltip: (i) => {
                            const lastLogin = new Date(i.lastLogin * 1000)
                            const joinDate = new Date(i.created * 1000)
                            const isStaff = i.group === 'staff'
                            const isActive = differenceInMonths(lastLogin, new Date()) === 0
                            const isNew = differenceInWeeks(joinDate, new Date()) === 0
                            const isSuperUser = i.loginCount > 10
                            return isStaff
                                ? 'User is a staff member'
                                : isNew
                                ? 'User has joined this week'
                                : isSuperUser && isActive
                                ? 'User has over 10 total logins and logged in within the last 30 days'
                                : isActive
                                ? 'User has logged in during the last 30 days'
                                : isSuperUser
                                ? 'User has over 10 total logins, but has not logged in during the last 30 days'
                                : 'User has not logged in during the past 30 days'
                        },
                        
                        calculateProps: (i) => {
                            const lastLogin = new Date(i.lastLogin * 1000)
                            const joinDate = new Date(i.created * 1000)
                            const isStaff = i.group === 'staff'
                            const isActive = differenceInMonths(lastLogin, new Date()) === 0
                            const isNew = differenceInWeeks(joinDate, new Date()) === 0
                            const isSuperUser = i.loginCount > 10
                            return {
                                color: isStaff
                                    ? '#1658CC'
                                    : isNew
                                    ? '#E9218E'
                                    : isSuperUser && isActive
                                    ? 'yellow'
                                    : isActive
                                    ? '#25B16C'
                                    : isSuperUser
                                    ? 'purple'
                                    : '#626C80',
                                text: isStaff
                                    ? 'Staff'
                                    : isNew
                                    ? 'New'
                                    : isSuperUser && isActive
                                    ? 'Super User'
                                    : isActive
                                    ? 'Active'
                                    : isSuperUser
                                    ? 'Inactive Super User'
                                    : 'Inactive',
                            }
                        },
                    },
                    {
                        header: 'Campaigns',
                        key: 'camapigns',
                        type: 'status',
                        tooltip: (i) => {
                            const campaigns = (i as User).UserAttribute.filter(a => a.key.includes("CAMPAIGN"))
                            return campaigns.length ? campaigns.map(a => {
                                const date = new Date(a.value)
                                return date.toLocaleDateString()+ ": "+a.key.split(":")[1]
                            }).join("\n")  : "No Campaigns sent"
                        },
                        
                        calculateProps: (i) => {
                            const campaigns = (i as User).UserAttribute.filter(a => a.key.includes("CAMPAIGN"))
                            return {
                                text: campaigns.length,
                            }
                        }, 
                    },
                    {
                        key: 'lastLogin',
                        type: 'double_text',
                        header: 'Last login',
                        tooltip: (row) =>
                            `Last login: ${format(new Date(row.lastLogin * 1000), 'PPPP')}`,
                        calculateProps: (row) => ({
                            title: `${getRelativeDateLabel(new Date(row.lastLogin * 1000))}`,
                            subtitle: `${row.loginCount} logins`,
                        }),
                    },
                    {
                        key: 'user_id',
                        type: 'id_badge',
                        header: 'ID',
                        tooltip: () => 'Click to copy UserID',
                        calculateProps: (i) => ({
                            onClick: () => {
                                toast({
                                    description: `Copied ${i.user_id} to clipboard`,
                                    status: 'info',
                                    duration: 3000,
                                    isClosable: true,
                                })
                                navigator.clipboard.writeText(i.user_id)
                            },
                        }),
                        format: (i) => i.user_id.slice(-4),
                    },
                    {
                        key: 'actions',
                        type: 'actions',
                        header: '',
                        calculateProps: (i) => [
                            {
                                onClick: () => window.open(`/users/${i.user_id}`, '_blank'),
                                icon: 'user',
                            },
                            {
                                onClick: () =>
                                    window.open(
                                        `https://analytics.amplitude.com/acacia/project/331932/search/${i.user_id}`,
                                        '_blank'
                                    ),
                                icon: 'analytics',
                            },
                        ],
                    },
                ]}
                title="All Users"
                badge={{ colorScheme: 'green', text: `${sortedData.length} Total` }}
                itemsPerPage={10}
            />
            {/* <UserList users={data.users.items} /> */}
            <Snackbar
                open={alertVisible}
                autoHideDuration={3000}
                onClose={() => setAlertVisible(false)}
            >
                <Alert severity="info">Copied UserID to Clipboard</Alert>
            </Snackbar>
        </div>
    )
}

export default Users
