import { ButtonBase, Menu, MenuItem } from '@material-ui/core'
import * as React from 'react'
import * as styles from './styles'
import { Insight } from 'graphql/types'
import { useMutation } from '@apollo/client'
import mutateInsight from 'graphql/mutations/mutateInsight'
import { BillButton } from './billButton'
import { DetailsInfo } from './details_info'
import { Notes } from './notes'

type Props = {
    InsightDetails: any
    refetch: any
}

const AcuInsightDetails = ({InsightDetails, refetch}: Props) => {
    const uniqueBills = new Map()
    let billNumber = 1
    InsightDetails.insights.forEach((v) => {
        if (!uniqueBills.has(v.BillHash)) {
            uniqueBills.set(v.BillHash, billNumber++)
        }
    })
    const [updateInsight]  = useMutation(mutateInsight)
    const [anchorEl, setAnchorEl] = React.useState<any>({});
    const open = (InsightID) => Boolean(anchorEl[InsightID]);
    const handleClick = (InsightID: string, event: React.MouseEvent<HTMLButtonElement>) => {
        const el = {...anchorEl }
        el[InsightID] = event.currentTarget
        setAnchorEl(el);
    };

    const handleClose = async (ins: Insight, Status: string) => {
        void updateInsight({
            variables: {
                UserID: ins.UserID,
                InsightID: ins.InsightID,
                Action: "status",
                Data: Status
            }
        })
        await refetch()
        const el = {...anchorEl }
        el[ins.InsightID] = null
        setAnchorEl(el);
    };



    return (
        <div style={{margin: "13px 8px"}}>
            <div>
                <div className={styles.row}>
                    { InsightDetails.insights.map((insight: Insight) => {
                        const thisInsight = insight
                        const badStatus = thisInsight.Status === "Escalate" || thisInsight.Status === "EscalatePending"
                        return (
                            <>
                                <div key={thisInsight.InsightID} className={styles.subrow}>

                                    <BillButton insight={thisInsight} uniqueBills={uniqueBills} />

                                    <ButtonBase style={badStatus ? {backgroundColor: "#FFCCCB"} : {backgroundColor: "#90EE90"}}
                                        className={styles.header} onClick={(e) => handleClick(thisInsight.InsightID, e)}>
                                        {thisInsight.Status === "" ? thisInsight.Error === "" ? "OK" : "Error" : thisInsight.Status}
                                    </ButtonBase>
                                    
                                    <Menu
                                        id={thisInsight.InsightID}
                                        anchorEl={anchorEl[thisInsight.InsightID]}
                                        open={open(thisInsight.InsightID)}
                                        MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                        }}
                                        onClose={() => {
                                            setAnchorEl({});
                                        }}
                                    >
                                        <MenuItem onClick={() => handleClose(thisInsight, "EscalateFixed")}>This has been fixed</MenuItem>
                                        <MenuItem onClick={() => handleClose(thisInsight, "EscalatePending")}>This will be fixed</MenuItem>
                                        <MenuItem onClick={() => handleClose(thisInsight, "EscalateClose")}>This cannot be fixed</MenuItem>
                                        
                                    </Menu>
                                    <DetailsInfo insight={thisInsight} />
                                    <Notes insight={thisInsight} />
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default AcuInsightDetails
