import { gql } from '@apollo/client'

export default gql`
    mutation runCompareTest(
        $Base64: String,
        $Nmi: String,
        $Rates: String,
        $InstitutionCode: String,
        $Discount: String
    ) {
        runCompareTest(
            Base64: $Base64
            Nmi: $Nmi
            Discount: $Discount
            Rates: $Rates
            InstitutionCode: $InstitutionCode
        ) {
            Plans {
                Name
                EstimatedTotalCost
                ComparisonTotalCost
                Url
                Accuraccy
                Status
                Logs
            }
                
            BillData {
                acctNMI
                totalDiscounts
                demand
                usage {
                    controlLoad1
                    controlLoad2
                    op
                    opStep1
                    opStep2
                    pk
                    pkStep1
                    pkStep2
                    pkStep3
                    sh
                    solar
                    supply
                }
            }

            AverageAccuraccy
            Provider
            TotalPlansCompare
            TotalPlansManual
        }
    }
`
