import React, { useEffect, useRef } from "react"
import MaterialTable, { MaterialTableProps } from "material-table"

export function CustomMaterialTable<T extends {}>(props: MaterialTableProps<T>) {
    const tableRef = useRef<any>();
    const openedPanels = useRef<Set<any>>(new Set());
    const oldFunction = useRef<any>();
    
    useEffect(() => {
        if (!oldFunction.current) {
        oldFunction.current = tableRef.current?.onToggleDetailPanel;
        }
    
        if (oldFunction.current === tableRef.current?.onToggleDetailPanel) {
        tableRef.current.onToggleDetailPanel = (path: any[], render: any) => {
            oldFunction.current(path, render);
    
            openedPanels.current.clear();
    
            tableRef.current.props.data?.forEach((item: any) => {
            if (item.tableData.showDetailPanel) {
                openedPanels.current.add(item.id);
            }
            });
        };
        }
    }, [tableRef]);
    
    return (
        <MaterialTable<T>
        tableRef={tableRef}
        {...props}
        data={
            (props.data as Array<any>)?.map(d => {
            return {
                ...d,
                tableData: {
                showDetailPanel: openedPanels.current.has(d.id) ? props.detailPanel : null,
                },
            };
            }) || []
        }
        />
    );
}
