import { Logo } from 'components/common/Logo'
import React from 'react'
import * as BiIcons from 'react-icons/bi'
import { Link } from 'react-router-dom'

import { Box, BoxProps, Center, Flex, Stack, useColorModeValue as mode } from '@chakra-ui/react'

import { AccountSwitcher } from '../common/AccountSwitcher'
import { NavItem } from '../common/NavItem'

export default function AcuSidebar({ children }: BoxProps) {
    return (
        <Box height="100vh" overflow="hidden" position="relative" zIndex="1">
            <Flex h="full" id="app-container">
                <Box w="64" bg="gray.900" color="white" fontSize="sm">
                    <Flex h="full" direction="column" px="4" py="4">
                        <Link to="/">
                            <Center>
                                <Logo h="8" mb="4" mt="4" />
                            </Center>
                        </Link>
                        <Stack spacing="8" flex="1" overflow="auto" pt="4">
                            <NavItem
                                to="/insights/"
                                icon={<BiIcons.BiAbacus />}
                                label="Insights"
                            />
                        </Stack>
                        <Box>
                            <Stack spacing="1">
                                <AccountSwitcher />
                            </Stack>
                        </Box>
                    </Flex>
                </Box>
                <Box bg={mode('white', 'gray.800')} flex="1" p="6" overflow="scroll">
                    {children}
                </Box>
            </Flex>
        </Box>
    )
}
