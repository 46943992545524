import { Button,ButtonBase,Dialog, Input, Menu, MenuItem, Paper } from '@material-ui/core'
import * as React from 'react'
import * as styles from './styles'
import { Insight } from 'graphql/types'
import { useMutation } from '@apollo/client'
import mutateInsight from 'graphql/mutations/mutateInsight'
import manualCompareDocument from 'graphql/mutations/manualCompare'
import { getSampleRates } from './sampleRates'
import { BillButton } from './billButton'
import { DetailsInfo } from './details_info'
import { Notes } from './notes'

 type Props = {
    InsightDetails: any
    refetch: any
 }

const InsightDetails = ({InsightDetails, refetch}: Props) => {
    const [manualCompareModal, setManualCompareModal] = React.useState<undefined | Insight>(undefined)
    const [customEmail, setCustomEmail] = React.useState<undefined | Insight>(undefined)
    const [currentBillRates, setCurrentBillRates] = React.useState<string>("")
    const [emailCopy, setEmailCopy] = React.useState<string>("")
    const [emailSubject, setEmailSubject] = React.useState<string>("")
    const [nmi, setNmi] = React.useState<string>("")
    const [institutionCode, setInstitutionCode] = React.useState<string>("")
    const uniqueBills = new Map()
    let billNumber = 1
    InsightDetails.insights.forEach((v) => {
        if (!uniqueBills.has(v.BillHash)) {
            uniqueBills.set(v.BillHash, billNumber++)
        }
    })
    const [updateInsight]  = useMutation(mutateInsight)
    const [manualCompareApi]  = useMutation(manualCompareDocument)
    const [anchorEl, setAnchorEl] = React.useState<any>({});
    const open = (InsightID) => Boolean(anchorEl[InsightID]);
    const handleClick = (InsightID: string, event: React.MouseEvent<HTMLButtonElement>) => {
        const el = {...anchorEl }
        el[InsightID] = event.currentTarget
        setAnchorEl(el);
    };

    const handleClose = async (ins: Insight, Status: string) => {
        if (Status === "ManualCompareModal") {
            setManualCompareModal(ins)
            return
        }
        if (Status === "CustomEmailModal") {
            setCustomEmail(ins)
            return
        }
        setManualCompareModal(undefined)

        if (Status === "ManualCompare") {
            await manualCompareApi({
                variables: {
                    Nmi: nmi,
                    UserID: ins.UserID,
                    CurrentBillRates: currentBillRates,
                    InstitutionCode: institutionCode
                }
            })
        } else if (Status === "CustomEmail") {
            const data = emailSubject  + "[[ENDSUBJECT]]" + emailCopy
            void updateInsight({
                variables: {
                    UserID: ins.UserID,
                    InsightID: ins.InsightID,
                    Action: "email",
                    Data: data
                }
            })
        } else {
            void updateInsight({
                variables: {
                    UserID: ins.UserID,
                    InsightID: ins.InsightID,
                    Action: "status",
                    Data: Status
                }
            })
        }
        await refetch()
        const el = {...anchorEl }
        el[ins.InsightID] = null
        setAnchorEl(el);
    };


    return (
        <div style={{margin: "13px 8px"}}>
            <div>
                <Dialog 
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                    open={!!manualCompareModal} 
                    onClose={() => setManualCompareModal(undefined)}
                >
                    <Paper style={{ width: '600px', height: '80%', padding: "20px" }} elevation={3}>
                        <div><span>Nmi: </span><Input placeholder="NMI" value={nmi} onChange={(e) => setNmi(e.currentTarget.value)} /></div>
                        <div><span>InstitutionCode: </span><Input placeholder="InstitutionCode" value={institutionCode} onChange={(e) => setInstitutionCode(e.currentTarget.value)} /></div>
                        
                        <div>
                            <textarea 
                                style={{ height: '700px', width:"100%", padding: "5px" }}
                                placeholder={getSampleRates}
                                value={currentBillRates} 
                                onChange={(e) => setCurrentBillRates(e.currentTarget.value)} 
                            />
                        </div>
                        <Button onClick={() => handleClose(manualCompareModal, "ManualCompare")}>Submit</Button>
                    </Paper>
                </Dialog>
                <Dialog 
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                    open={!!customEmail} 
                    onClose={() => setCustomEmail(undefined)}
                >
                    <Paper style={{ width: '600px', height: '80%', padding: "20px" }} elevation={3}>
                        <div><span>Subject: </span><Input value={emailSubject} onChange={(e) => setEmailSubject(e.currentTarget.value)} /></div>
                        <div>
                            <textarea 
                                style={{ height: '700px', width:"100%", padding: "5px" }}
                                placeholder="Email Copy"
                                value={emailCopy} 
                                onChange={(e) => setEmailCopy(e.currentTarget.value)} 
                            />
                        </div>
                        <Button onClick={() => handleClose(customEmail, "CustomEmail")}>Send</Button>
                    </Paper>
                </Dialog>
                <div className={styles.row}>
                    { InsightDetails.insights.map((insight: Insight) => {
                        const thisInsight = insight
                        const badStatus = thisInsight.Status === "VerifyPending" || thisInsight.Status === "Escalate" || thisInsight.Status === "Error" || (thisInsight.Status === "" && thisInsight.Error !== "")
                        return (
                            <>
                                <div key={thisInsight.InsightID} className={styles.subrow}>

                                    { thisInsight.BillHash ? (
                                        <BillButton insight={thisInsight} uniqueBills={uniqueBills} />
                                    ) : (
                                        <div className={styles.header} style={{background: "white", textAlign: "center"}}>NMI INSIGHT</div>
                                    )}
                                    
                                    <ButtonBase style={badStatus ? {backgroundColor: "#FFCCCB"} : {backgroundColor: "#90EE90"}}
                                        className={styles.header} onClick={(e) => handleClick(thisInsight.InsightID, e)}>
                                        {thisInsight.Status === "" ? thisInsight.Error === "" ? "OK" : "Error" : thisInsight.Status}
                                    </ButtonBase>
                                    
                                    <Menu
                                        id={thisInsight.InsightID}
                                        anchorEl={anchorEl[thisInsight.InsightID]}
                                        open={open(thisInsight.InsightID)}
                                        MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                        }}
                                        onClose={() => {
                                            setAnchorEl({});
                                        }}
                                    >
                                        { thisInsight.Status === "VerifyPending" ? (
                                            <>
                                                <MenuItem onClick={() => handleClose(thisInsight, "Verified")}>Verified</MenuItem>
                                                <MenuItem onClick={() => handleClose(thisInsight, "VerifyFail")}>VerifyFail</MenuItem>
                                            </>
                                        ) : (
                                            <>
                                                <MenuItem onClick={() => handleClose(thisInsight, "OK")}>OK</MenuItem>
                                                <MenuItem onClick={() => handleClose(thisInsight, "Ignore")}>Ignore</MenuItem>
                                                <MenuItem onClick={() => handleClose(thisInsight, "Error")}>Error</MenuItem>
                                                { thisInsight.BillHash && (
                                                    <>
                                                        <MenuItem onClick={() => handleClose(thisInsight, "Escalate")}>Escalate</MenuItem>
                                                        <MenuItem onClick={() => handleClose(thisInsight, "ManualCompareModal")}>ManualCompare</MenuItem>
                                                        <MenuItem onClick={() => handleClose(thisInsight, "CustomEmailModal")}>Custom Email</MenuItem>
                                                        <MenuItem onClick={() => handleClose(thisInsight, "Fixed")}>Fixed</MenuItem>
                                                        <MenuItem onClick={() => handleClose(thisInsight, "FixedRegenerate")}>FixedRegenerate</MenuItem>
                                                        <MenuItem onClick={() => handleClose(thisInsight, "VerifyPending")}>Verify</MenuItem>
                                                        
                                                    </>
                                                ) }
                                            </>
                                        )}
                                        
                                    </Menu>
                                    <DetailsInfo insight={thisInsight} />
                                    <Notes insight={thisInsight} />
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default InsightDetails
