import { Insight } from "graphql/types"
import React from "react"
import * as styles from './styles'
import { useApolloClient, useMutation } from "@apollo/client"
import mutateInsight from "graphql/mutations/mutateInsight"
import adminInsights from "graphql/queries/adminInsights"

type Props = {
    insight: Insight
}
export const Notes = ({insight}:Props) => {
    const [updateInsight]  = useMutation(mutateInsight)
    const addNotes = async (insight: Insight, notes: string) => {
        await updateInsight({
            variables: {
                UserID: insight.UserID,
                InsightID: insight.InsightID,
                Action: "notes",
                Data: notes
            }, 
            refetchQueries: ["adminInsights"]
        })
    }
    return (
        <>
            <div className={styles.info} style={{border: "1px solid #ccc", padding: "5px", margin: "4px"}}>
                {insight.Notes.split("\n").map((v) => {
                    return <p key={v}>{v}</p>
                })}
            </div>
            <div className={styles.info} style={{border: "1px solid #ccc", padding: "5px", margin: "4px"}}>
                <textarea 
                    style={{width: "100%", height: "100px"}}
                    placeholder='Type new notes into this textarea and then blur (click anywhere or press tab) to save' 
                    className={styles.textInput} 
                    onBlur={(e) => { void addNotes(insight, e.target.value)}} 
                />
            </div>
        </>
    )
}