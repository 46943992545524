import gql from 'graphql-tag'

export default gql`
    query adminInsights($PartnerID: String) {
        adminInsights(PartnerID: $PartnerID) {
            UserEmail
            InsightID
            UserID
            GeneratedAt
            Domain
            Error
            Notes
            BillHash
            BillId
            Status
            Summary {
                ProviderName
            }
        }
    }
`
