import { Button, Input, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core"
import { ExtractBillData } from "graphql/types"
import React from "react"

interface Props {
    extract: ExtractBillData
}

export const ExtractView = ({ extract }: Props) => {
    return (
        <div style={{ border: '1px solid #eee', padding: '10px' }}>
            <Table>         
                <TableHead>
                    <TableRow>
                        <TableCell style={{fontWeight: "bold"}}>Key</TableCell>
                        <TableCell style={{fontWeight: "bold"}}>Original</TableCell>
                        <TableCell style={{fontWeight: "bold"}}>New</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Object.entries(extract).map(([key, value]) => {
                            if (typeof value === 'object') {
                                return (
                                    <TableRow key={key}>
                                        <TableCell>{key}</TableCell>
                                        <TableCell>{JSON.stringify(value, null, 2)}</TableCell>
                                        <TableCell><Input /></TableCell>
                                    </TableRow>
                                )
                            }
                            return (
                                <TableRow key={key}>
                                    <TableCell>{key}</TableCell>
                                    <TableCell>{JSON.stringify(value, null, 2)}</TableCell>
                                    <TableCell><Input /></TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
            <div style={{ border: '1px solid #eee', padding: '10px', background: '#f9f9f9' }}>
                <div style={{textAlign:"right"}}>
                    <Button onClick={() => {}} style={{background: "lightgreen", margin: "2px"}}>Verify this Insight</Button>
                    <Button onClick={() => {}} style={{background: "lightgreen", margin: "2px"}}>Regenerate Insight for User</Button>
                </div>
            </div>
        </div>
    )
}