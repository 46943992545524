import { useMutation } from '@apollo/client';
import { Accordion, AccordionDetails, AccordionSummary, withStyles } from '@material-ui/core';
import runCompareTest from 'graphql/mutations/runCompareTest';
import React, { useState } from 'react';
import * as styles from './styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'

interface RateAmount {
    Name: string
    Rate: string
    Qty: string
}

export const CalcTest = () => {
    const [expanded, setExpanded] = React.useState<Array<string>>([])
    const [base64, setBase64] = useState<string | null>(null);
    const [nmi, setNmi] = useState<string | null>(null);
    const [discount, setDiscount] = useState<string | null>("0");
    const [rates, setRates] = useState<RateAmount[]>([]);
    const [runTest, result] = useMutation(runCompareTest);
    const Accordion = withStyles(styles.accordianStyles)(MuiAccordion)
    const AccordionSummary = withStyles(styles.AccordionSummaryStyles)(MuiAccordionSummary)
    const AccordionDetails = withStyles(styles.AccordionDetailStyles)(MuiAccordionDetails)
    const handleChange = (panel) => (event, newExpanded) => {
        if (expanded.indexOf(panel) > -1) {
            setExpanded(expanded.filter((a) => a != panel))
        } else {
            setExpanded([...expanded, panel])
        }
    }
    const submit = async () => {
        await runTest({
            variables: {
                Nmi: nmi,
                Rates: JSON.stringify(rates),
                Base64: base64.split(',')[1],
                Discount: discount
            }, 
            onError: (error) => {
                alert("bruh");
                alert(error);
            },
            onCompleted: (data) => {
                console.log(data);
                setNmi(data.runCompareTest.BillData.acctNMI);
                Object.keys(data.runCompareTest.BillData.usage).forEach((key) => {
                    setQty(key, data.runCompareTest.BillData.usage[key]);
                });
                setDiscount(data.runCompareTest.BillData.totalDiscounts);
            }
        });
    }
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type === 'application/pdf') {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64(reader.result as string);
            };
            reader.readAsDataURL(file);
        } else {
            alert('Please upload a PDF file.');
        }
    };
    const setQty = (key: string, qty: string) => {
        if (!rates.find((a => a.Name == key))) {
            setRates([...rates, {Name: key, Rate: "", Qty: qty}]);
            return;
        }
        const newRates = rates.map((item) => {
            if (item.Name === key) {
                return {
                    ...item,
                    Qty: qty,
                };
            }
            return item;
        });
        setRates(newRates);
    }

    return (
        <div>
            <h1>CalcTest</h1>
            <div>
                <input type="file" accept="application/pdf" onChange={handleFileChange} />
            </div>

            { base64 && !result.data && (
                <div>
                    { result.loading ? <div>Loading...</div> : <div>
                        <button onClick={() => submit()}>Calculate</button>
                    </div>}
                </div>
            )}
            
            {base64 && result.data && (
                <div>
                    <div>
                        <span style={{width:"200px", display:"inline-block"}}>NMI:</span>
                        <input type={'text'} value={nmi}  onChange={(e) => setNmi(e.currentTarget.value)} />
                    </div>
                    <div>
                        <span style={{width:"200px", display:"inline-block"}}>Discount amount:</span>
                        <input type={'text'} value={discount}  onChange={(e) => setDiscount(e.currentTarget.value)} />
                    </div>
                    <div>
                        <span style={{width:"200px", display:"inline-block"}}>Peak:</span>
                        <input type={'text'} value={rates.find((a => a.Name == "pk"))?.Qty}  onChange={(e) => setQty("pk", e.currentTarget.value)} placeholder='Qty' />
                    </div>
                    <div>
                    <span style={{width:"200px", display:"inline-block"}}>OffPeak:</span>
                        <input type={'text'} value={rates.find((a => a.Name == "op"))?.Qty}  onChange={(e) => setQty("op", e.currentTarget.value)} placeholder='Qty' />
                    </div>
                    <div>
                    <span style={{width:"200px", display:"inline-block"}}>Shoulder:</span>
                        <input type={'text'} value={rates.find((a => a.Name == "sh"))?.Qty}  onChange={(e) => setQty("sh", e.currentTarget.value)} placeholder='Qty' />
                    </div>
                    <div>
                    <span style={{width:"200px", display:"inline-block"}}>CL1:</span>
                        <input type={'text'} value={rates.find((a => a.Name == "controlLoad1"))?.Qty}  onChange={(e) => setQty("controlLoad1", e.currentTarget.value)} placeholder='Qty' />
                    </div>
                    <div>
                    <span style={{width:"200px", display:"inline-block"}}>CL2:</span>
                        <input type={'text'} value={rates.find((a => a.Name == "controlLoad2"))?.Qty}  onChange={(e) => setQty("controlLoad2", e.currentTarget.value)} placeholder='Qty' />
                    </div>
                    <div>
                        <span style={{width:"200px", display:"inline-block"}}>Supply Charge:</span>
                        <input type={'text'} value={rates.find((a => a.Name == "supply"))?.Qty}  onChange={(e) => setQty("supply", e.currentTarget.value)} placeholder='Qty' />
                    </div>
                    <div>
                        <span style={{width:"200px", display:"inline-block"}}>Solar FiT:</span>
                        <input type={'text'} value={rates.find((a => a.Name == "solar"))?.Qty}  onChange={(e) => setQty("solar", e.currentTarget.value)} placeholder='Qty' />
                    </div>
                    
                </div>
            )}

            {result.data && result.data.runCompareTest.Plans && ( 
                <div style={{border: "1px solid grey", padding: "10px", marginTop:"15px"}}>
                    <h1 style={{fontWeight: "bold"}}>Results</h1>
                    <div>
                        <div>
                            <span style={{width:"200px", display:"inline-block"}}>Average Accuracy:</span>  
                            <span>{result.data.runCompareTest.AverageAccuraccy}</span>
                        </div>
                        <div>
                            <span style={{width:"200px", display:"inline-block"}}>Provider:</span>  
                            <span>{result.data.runCompareTest.Provider}</span>
                        </div>
                        <div>
                            <span style={{width:"200px", display:"inline-block"}}>TotalPlansCompare:</span>  
                            <span>{result.data.runCompareTest.TotalPlansCompare}</span>
                        </div>
                        <div>
                            <span style={{width:"200px", display:"inline-block"}}>TotalPlansManual:</span>  
                            <span>{result.data.runCompareTest.TotalPlansManual}</span>
                        </div>
                        <div>
                            <h1 style={{fontWeight: "bold"}}>Plans</h1>
                            {result.data.runCompareTest.Plans.map((plan: any, index: number) => (

                                <Accordion
                                    square
                                    expanded={expanded.indexOf(plan.Name) > -1}
                                    onChange={handleChange(plan.Name)}
                                >
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                        <div style={{display: "grid", gridTemplateColumns: "600px 400px"}}>
                                            <div>{plan.Name}</div>
                                            <div>{Math.round(plan.Accuraccy * 10000) / 100} %</div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <span style={{width:"200px", display:"inline-block"}}>Estimated Total Cost:</span>  
                                            <span>{plan.EstimatedTotalCost}</span>
                                        </div>
                                        <div>
                                            <span style={{width:"200px", display:"inline-block"}}>Comparison Total Cost:</span>  
                                            <span>{plan.ComparisonTotalCost}</span>
                                        </div>
                                        {plan.Url && <div style={{border:"1px solid grey", display:"inline-block"}}>
                                            <span><a rel="noreferrer" target="_blank" href={plan.Url}>Energy made easy</a></span>
                                        </div> }
                                        <div>
                                            <span style={{width:"200px", display:"inline-block"}}>Status:</span>  
                                            <span>{plan.Status}</span>
                                        </div>
                                        <div>
                                            <span style={{width:"200px", display:"inline-block"}}>Logs:</span>  
                                            <div style={{border: "1px solid grey", padding: "10px", marginTop:"15px"}}>
                                                {plan.Logs.map(a => (
                                                    <div key={a}>{a}</div>
                                                ))}
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}   
