import { useQuery } from '@apollo/client'
import * as React from 'react'
import * as BiIcons from 'react-icons/bi'
import * as CommonStyles from '../common/CommonStyles'
import MaterialTable, { Icons } from 'material-table'
import { tableIcons } from 'components/common/MaterialIcons'
import { Insight } from 'graphql/types'
import adminInsights from 'graphql/queries/adminInsights'
import InsightDetails from './details'
import AcuInsightDetails from './acu_details'
import { CustomMaterialTable } from './customTable'

const AcuInsights = () => {
    const { loading, error, data, refetch } = useQuery(adminInsights, { 
        fetchPolicy: 'no-cache', 
        variables: { PartnerID: "acu" }
    })

    if (loading) return <div>Loading</div>
    if (error) return <div>failure {console.error(error)} </div>
    
    const foundUsers = new Map<string, Insight[]>()
    if (!data) return <div>No data</div>
    data.adminInsights
        .filter(a => a.Domain === "energy")
        .forEach((insight: Insight) => {
            if (!foundUsers.has(insight.UserID)) {
                foundUsers.set(insight.UserID, [])
            }
            foundUsers.get(insight.UserID)?.push(insight)
        })


    const arr = Array.from(foundUsers, ([name, value]) => {
        const hasError = value.filter((v) => v.Error !== "")
        const uniqueErrors = new Set(hasError.filter(a => a.Error !== "").
            map((v) => v.Error.substring(39))).size
        const uniqueBills = new Set(value.map((v) => v.BillHash)).size
        const firstUploadDate = new Date(value.sort((a, b) => new Date(a.GeneratedAt).getTime() - new Date(b.GeneratedAt).getTime())[0].GeneratedAt).toLocaleString()
        const lastUploadDate = new Date(value.sort((a, b) => new Date(b.GeneratedAt).getTime() - new Date(a.GeneratedAt).getTime())[0].GeneratedAt).toLocaleString()
        const badStatus = Boolean(value.filter((v) => v.Status === "EscalatePending" || v.Status === "Escalate" || v.Status === "Error" || (v.Status === "" && v.Error !== "")).length)
        return {
            errorUploads: hasError.length,
            name: name,
            email: value[0].UserEmail,
            totalUploads: value.length,
            uniqueErrors,
            uniqueBills,
            lastUploadDate, 
            firstUploadDate,
            insights: value, 
            badStatus
        }
    })

    const tableData = arr
    return (
        <div style={{position:'relative'}}>
            <div>
                <BiIcons.BiWindow size={35} style={{ display: 'inline-block' }} />
                <h1
                    style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
                    className={CommonStyles.MainHeaderClass}
                >
                    Energy Insight Issues
                </h1>
            </div>
            <div>
                <CustomMaterialTable
                    icons={tableIcons as Icons}
                    title={''}
                    columns={[
                        { title: 'UserID', field: 'name', render: (rowData) => <a href={`/users/${rowData.name}`}>{rowData.name}</a> },
                        { title: 'Email', field: 'email'},
                        { title: 'First Upload Date', field: 'firstUploadDate'},
                        { title: 'Last Upload Date', field: 'lastUploadDate'},
                        { title: 'Total Uploads', field: 'totalUploads'},
                        { title: 'Failed Uploads', field: 'errorUploads'},
                        { title: 'Unique Errors', field: 'uniqueErrors'},
                        { title: 'Unique Bills', field: 'uniqueBills'},
                        { title: 'Alert Status', field: 'badStatus', defaultFilter: ["true"], lookup: { true: 'true', false: 'false' },},
                        
                    ]}
                    data={tableData}
                    options={{
                        showTitle: false,
                        sorting: true,
                        filtering: true,
                        search: true,
                        pageSize: 50,
                    }}
                    detailPanel={(rowData) => {
                        return <AcuInsightDetails InsightDetails={rowData as any} refetch={refetch} />
                    }}
                />
            </div>
        </div>
    )
}

export default AcuInsights
