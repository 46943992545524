import * as React from 'react'
import { Box } from '@chakra-ui/react'

const AcuDashboard = () => {
    
    return (
        <Box borderWidth="4px" borderStyle="dashed" rounded="md">
            
        </Box>
    )
}


export default AcuDashboard
